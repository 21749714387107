// Base Colors
$color-black: #000;
$color-text: #313131;
$color-text-light: #737373;
$color-gray: #9D9D9D;

$color-bg: #f5f5f5;
$color-bg-light: #fafafa;

// Functional Colors
$color-border: #dbdbdb;
$color-border-light: $color-bg;
$color-autofill: $color-text;
$color-placeholder: $color-text-light;
